import {
  convertToRoleValueList,
  CRUD,
  READ,
  READ_UPDATE,
  AllRoles,
  RolePermissions,
  Roles,
} from "src/utils/route-guard/permissions";

// PRODUCT LIST
const productList = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_EDITOR: AllRoles.PRODUCT_EDITOR,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
  CUSTOMER_SUPPORT: AllRoles.CUSTOMER_SUPPORT,
  USER: AllRoles.USER,
};

type ProductListRoles = typeof productList;
interface ProductListUi {
  canSeeCreateButton: Roles;
}

export const ProductListPermissions: RolePermissions<ProductListRoles, ProductListUi> = {
  canVisit: convertToRoleValueList(productList),
  ui: { canSeeCreateButton: [AllRoles.SUPER_ADMIN, AllRoles.PRODUCT_ADMIN] },
  permissions: {
    SUPER_ADMIN: CRUD,
    PRODUCT_EDITOR: READ,
    PRODUCT_ADMIN: CRUD,
    LOT_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_ADMIN: READ,
    WAREHOUSE_EDITOR: READ,
    CUSTOMER_SUPPORT: READ,
    USER: READ,
  },
};

// PRODUCT DETAIL
const productDetail = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_EDITOR: AllRoles.PRODUCT_EDITOR,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  LOT_PREP_EDITOR: AllRoles.LOT_PREP_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
  CUSTOMER_SUPPORT: AllRoles.CUSTOMER_SUPPORT,
  USER: AllRoles.USER,
};

type ProductDetailRoles = typeof productDetail;
interface ProductDetailUi {
  canSeeDuplicateButton: Roles;
  canSeeEditButton: Roles;
}

export const ProductDetailPermissions: RolePermissions<ProductDetailRoles, ProductDetailUi> = {
  canVisit: convertToRoleValueList(productDetail),
  ui: {
    canSeeDuplicateButton: [AllRoles.SUPER_ADMIN, AllRoles.PRODUCT_ADMIN],
    canSeeEditButton: [AllRoles.SUPER_ADMIN, AllRoles.PRODUCT_EDITOR, AllRoles.PRODUCT_ADMIN],
  },
  permissions: {
    SUPER_ADMIN: CRUD,
    PRODUCT_EDITOR: READ_UPDATE,
    PRODUCT_ADMIN: CRUD,
    LOT_EDITOR: READ,
    LOT_PREP_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_ADMIN: READ,
    WAREHOUSE_EDITOR: READ,
    CUSTOMER_SUPPORT: READ,
    USER: READ,
  },
};

// PRODUCT DETAIL EDIT
const productDetailEdit = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_EDITOR: AllRoles.PRODUCT_EDITOR,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
};

type ProductDetailEditRoles = typeof productDetailEdit;
interface ProductDetailEditUi {
  canSeeDeleteButton: Roles;
  canSeeSaveButton: Roles;
}

export const ProductDetailEditPermissions: RolePermissions<ProductDetailEditRoles, ProductDetailEditUi> = {
  canVisit: convertToRoleValueList(productDetailEdit),
  ui: {
    canSeeDeleteButton: [AllRoles.SUPER_ADMIN, AllRoles.PRODUCT_ADMIN],
    canSeeSaveButton: [AllRoles.SUPER_ADMIN, AllRoles.PRODUCT_EDITOR, AllRoles.PRODUCT_ADMIN],
  },
  permissions: {
    SUPER_ADMIN: CRUD,
    PRODUCT_EDITOR: READ_UPDATE,
    PRODUCT_ADMIN: CRUD,
  },
};

// PRODUCT DETAIL CREATE
const productDetailCreate = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
};

type ProductDetailCreateRoles = typeof productDetailCreate;
interface ProductDetailCreateUi {
  canSeeSaveButton: Roles;
}

export const ProductDetailCreatePermissions: RolePermissions<ProductDetailCreateRoles, ProductDetailCreateUi> = {
  canVisit: convertToRoleValueList(productDetailCreate),
  ui: {
    canSeeSaveButton: [AllRoles.SUPER_ADMIN, AllRoles.PRODUCT_ADMIN],
  },
  permissions: {
    SUPER_ADMIN: CRUD,
    PRODUCT_ADMIN: CRUD,
  },
};
