import { FC } from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";
import {
  AnglesLeft,
  ArrowRotateLeft,
  BarcodeRead,
  BoxLight,
  BoxRegular,
  BoxCircleCheck,
  BoxOpen,
  CalendarXmark,
  Check,
  Copy,
  CircleCheck,
  ClipboardCheck,
  ClipboardList,
  DeliveredToCustomer,
  Download,
  FileInvoice,
  FloppyDisk,
  HouseChimneyLight,
  HouseChimneyRegular,
  InboxIn,
  ListUl,
  MagnifyingGlass,
  PenToSquare,
  Plus,
  Print,
  QrcodeLight,
  QrcodeRegular,
  Question,
  Shield,
  SquarePlus,
  TrashCan,
  Vial,
  VialsLight,
  VialsRegular,
  WarehouseFull,
  Xmark,
  PaperPlane,
} from "dc-parts/icon/Icons";

export enum Icons {
  AnglesLeft = "AnglesLeft",
  ArrowRotateLeft = "ArrowRotateLeft",
  BarcodeRead = "BarcodeRead",
  BoxLight = "BoxLight",
  BoxRegular = "BoxRegular",
  BoxCircleCheck = "BoxCircleCheck",
  BoxOpen = "BoxOpen",
  CalendarXmark = "CalendarXmark",
  Check = "Check",
  Download = "Download",
  CircleCheck = "CircleCheck",
  ClipboardCheck = "ClipboardCheck",
  ClipboardList = "ClipboardList",
  Copy = "Copy",
  DeliveredToCustomer = "DeliveredToCustomer",
  FileInvoice = "FileInvoice",
  FloppyDisk = "FloppyDisk",
  HouseChimneyLight = "HouseChimneyLight",
  HouseChimneyRegular = "HouseChimneyRegular",
  InboxIn = "InboxIn",
  ListUl = "ListUl",
  MagnifyingGlass = "MagnifyingGlass",
  PenToSquare = "PenToSquare",
  Plus = "Plus",
  Print = "Print",
  QrcodeLight = "QrcodeLight",
  QrcodeRegular = "QrcodeRegular",
  Question = "Question",
  Shield = "Shield",
  SquarePlus = "SquarePlus",
  TrashCan = "TrashCan",
  Vial = "Vial",
  VialsLight = "VialsLight",
  VialsRegular = "VialsRegular",
  WarehouseFull = "WarehouseFull",
  Xmark = "Xmark",
  PaperPlane = "PaperPlane",
}

export const allIcons: { [key in Icons]: FC<SvgIconProps> } = {
  AnglesLeft,
  ArrowRotateLeft,
  BarcodeRead,
  BoxLight,
  BoxRegular,
  BoxCircleCheck,
  BoxOpen,
  CalendarXmark,
  Check,
  ClipboardCheck,
  ClipboardList,
  Copy,
  Download,
  CircleCheck,
  DeliveredToCustomer,
  FileInvoice,
  FloppyDisk,
  HouseChimneyLight,
  HouseChimneyRegular,
  InboxIn,
  ListUl,
  MagnifyingGlass,
  PenToSquare,
  Plus,
  Print,
  QrcodeLight,
  QrcodeRegular,
  Question,
  Shield,
  SquarePlus,
  TrashCan,
  Vial,
  VialsLight,
  VialsRegular,
  WarehouseFull,
  Xmark,
  PaperPlane,
};
