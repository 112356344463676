import React, { FC } from "react";

const Loading: FC<{ message?: string }> = ({ message }): JSX.Element => {
  return (
    <>
      <div>
        {
          //This is just some test code
        }
        <img width="200" src="./assets/logo_green.svg" />
        <br />
        {message ? message : "Loading... Please wait... If this takes a long time something might be wrong..."}
      </div>
    </>
  );
};
export default Loading;
