import { convertToRoleValueList, READ, RolePermissions, AllRoles } from "src/utils/route-guard/permissions";

// invoice LIST
const invoiceList = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  FINANCIAL_ADMIN: AllRoles.FINANCIAL_ADMIN,
};

type InvoiceListRoles = typeof invoiceList;
type InvoiceListUi = Record<string, never>;

export const InvoiceListPermissions: RolePermissions<InvoiceListRoles, InvoiceListUi> = {
  canVisit: convertToRoleValueList(invoiceList),
  ui: {},
  permissions: {
    SUPER_ADMIN: READ,
    FINANCIAL_ADMIN: READ,
  },
};

// invoice create
const invoiceCreate = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  FINANCIAL_ADMIN: AllRoles.FINANCIAL_ADMIN,
};

type InvoiceCreateRoles = typeof invoiceCreate;
type InvoiceCreateUi = Record<string, never>;

export const InvoiceCreatePermissions: RolePermissions<InvoiceCreateRoles, InvoiceCreateUi> = {
  canVisit: convertToRoleValueList(invoiceCreate),
  ui: {},
  permissions: {
    SUPER_ADMIN: READ,
    FINANCIAL_ADMIN: READ,
  },
};
