import { createTheme } from "@mui/material/styles";
export interface ISxTheme {
  [key: string]: string | boolean | any;
}

export const themeOptions = createTheme({
  palette: {
    primary: {
      main: "#0074A3",
      light: "#338FB5",
      dark: "#005172",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#424242",
      light: "#616161",
      dark: "#212121",
      contrastText: "#ffffff",
    },
    background: {
      default: "#FAFAFA",
      paper: "#ffffff",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
      disabled: "#9E9E9E",
    },
    error: {
      main: "#d32f2f",
    },
    warning: {
      main: "#fbc02d",
    },
    info: {
      main: "#0277bd",
    },
    success: {
      main: "#2e7d32",
    },
    action: {
      focus: "#c35a5c",
      hover: "#F3F3F3",
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "1.125rem",
    },
    subtitle1: {
      fontSize: "1rem",
    },
    subtitle2: {
      fontSize: "0.875rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "1rem",
    },
    caption: {
      fontSize: "0.75rem",
    },
    overline: {
      fontSize: "0.75rem",
    },
  },
});

export const themeOptionsDev = createTheme({
  ...themeOptions,
  palette: {
    primary: {
      main: "#F47174",
      light: "#f79b9d",
      dark: "#c35a5c",
      contrastText: "#ffffff",
    },
  },
});
