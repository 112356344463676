import React, { FC } from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { allIcons, Icons } from "./IconIndex";

interface IDCIcon extends SvgIconProps {
  iconName: string;
}

export const DCIcon: FC<IDCIcon> = (props) => {
  const { iconName, ...rest } = props;
  const SvgToRender = allIcons[iconName as Icons];
  return <SvgToRender {...rest} />;
};
