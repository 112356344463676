import { convertToRoleValueList, READ, RolePermissions, AllRoles } from "src/utils/route-guard/permissions";

// UDI LIST
const udiList = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_EDITOR: AllRoles.PRODUCT_EDITOR,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  LOT_PREP_EDITOR: AllRoles.LOT_PREP_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
  CUSTOMER_SUPPORT: AllRoles.CUSTOMER_SUPPORT,
  USER: AllRoles.USER,
};

type UdiListRoles = typeof udiList;
type UdiListUi = Record<string, never>;

export const UdiListPermissions: RolePermissions<UdiListRoles, UdiListUi> = {
  canVisit: convertToRoleValueList(udiList),
  ui: {},
  permissions: {
    SUPER_ADMIN: READ,
    PRODUCT_EDITOR: READ,
    PRODUCT_ADMIN: READ,
    LOT_EDITOR: READ,
    LOT_PREP_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_EDITOR: READ,
    MANUFACTURING_ADMIN: READ,
    WAREHOUSE_EDITOR: READ,
    CUSTOMER_SUPPORT: READ,
    USER: READ,
  },
};
