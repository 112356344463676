import {
  convertToRoleValueList,
  CRUD,
  READ,
  AllRoles,
  RolePermissions,
  Roles,
} from "src/utils/route-guard/permissions";

// LOT MANAGEMENT LIST
const lotManagementList = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_EDITOR: AllRoles.PRODUCT_EDITOR,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  LOT_PREP_EDITOR: AllRoles.LOT_PREP_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
  CUSTOMER_SUPPORT: AllRoles.CUSTOMER_SUPPORT,
  USER: AllRoles.USER,
};

type LotManagementListRoles = typeof lotManagementList;
interface LotManagementListUi {
  canSeeCreateButton: Roles;
}

export const LotManagementListPermissions: RolePermissions<LotManagementListRoles, LotManagementListUi> = {
  canVisit: convertToRoleValueList(lotManagementList),
  ui: { canSeeCreateButton: [AllRoles.SUPER_ADMIN, AllRoles.LOT_EDITOR, AllRoles.MANUFACTURING_ADMIN] },
  permissions: {
    SUPER_ADMIN: CRUD,
    PRODUCT_EDITOR: READ,
    PRODUCT_ADMIN: READ,
    LOT_EDITOR: CRUD,
    LOT_PREP_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_EDITOR: READ,
    MANUFACTURING_ADMIN: CRUD,
    WAREHOUSE_EDITOR: READ,
    CUSTOMER_SUPPORT: READ,
    USER: READ,
  },
};

// LOT MANAGEMENT DETAIL
const lotManagementDetail = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_EDITOR: AllRoles.PRODUCT_EDITOR,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  LOT_PREP_EDITOR: AllRoles.LOT_PREP_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
  CUSTOMER_SUPPORT: AllRoles.CUSTOMER_SUPPORT,
  USER: AllRoles.USER,
};

type LotManagementDetailRoles = typeof lotManagementDetail;
interface LotManagementDetailUi {
  canSeeEditButton: Roles;
  canSeePrepareProductionButton: Roles;
}

export const LotManagementDetailPermissions: RolePermissions<LotManagementDetailRoles, LotManagementDetailUi> = {
  canVisit: convertToRoleValueList(lotManagementDetail),
  ui: {
    canSeeEditButton: [AllRoles.SUPER_ADMIN, AllRoles.LOT_EDITOR, AllRoles.MANUFACTURING_ADMIN],
    canSeePrepareProductionButton: [AllRoles.SUPER_ADMIN, AllRoles.LOT_EDITOR, AllRoles.MANUFACTURING_ADMIN],
  },
  permissions: {
    SUPER_ADMIN: CRUD,
    PRODUCT_EDITOR: READ,
    PRODUCT_ADMIN: READ,
    LOT_EDITOR: CRUD,
    LOT_PREP_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_EDITOR: READ,
    MANUFACTURING_ADMIN: CRUD,
    WAREHOUSE_EDITOR: READ,
    CUSTOMER_SUPPORT: READ,
    USER: READ,
  },
};

// LOT MANAGEMENT CREATE
const lotManagementCreate = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
};

type LotManagementCreateRoles = typeof lotManagementCreate;
type LotManagementCreateUi = Record<string, never>;

export const LotManagementCreatePermissions: RolePermissions<LotManagementCreateRoles, LotManagementCreateUi> = {
  canVisit: convertToRoleValueList(lotManagementCreate),
  ui: {},
  permissions: {
    SUPER_ADMIN: CRUD,
    LOT_EDITOR: CRUD,
    MANUFACTURING_ADMIN: CRUD,
  },
};

// LOT MANAGEMENT EDIT
const lotManagementEdit = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
};

type LotManagementEditRoles = typeof lotManagementEdit;
interface LotManagementEditUi {
  canSeeDeleteButton: Roles;
}

export const LotManagementEditPermissions: RolePermissions<LotManagementEditRoles, LotManagementEditUi> = {
  canVisit: convertToRoleValueList(lotManagementEdit),
  ui: { canSeeDeleteButton: [AllRoles.SUPER_ADMIN, AllRoles.LOT_EDITOR, AllRoles.MANUFACTURING_ADMIN] },
  permissions: {
    SUPER_ADMIN: CRUD,
    LOT_EDITOR: CRUD,
    MANUFACTURING_ADMIN: CRUD,
  },
};
