//Example .env file for development. (Place the .env file in the root directory)
/*
PRODUCTION=false
#Test enviroment URL: "https://apidev.dynamiccode.se"
#Production enviroment URL: "https://api.dynamiccode.se"
BACKEND_URL="http://localhost" 
BARTENDER_URL="http://localhost:81"
SET_ROLE_AS_DEV=true
*/

interface IConfig {
  production: boolean;
  backendUrl: string;
  bartenderUrl: string;
  setRoleAsDev: boolean;
}

const defaultValuesDev: IConfig = {
  production: true,
  backendUrl: "http://localhost",
  bartenderUrl: "http://localhost:81",
  setRoleAsDev: false,
};

const getConfig = (): IConfig => {
  try {
    //? We do production as default because we don't want anyone to accidentally deploy a development version.
    const PRODUCTION = process.env.PRODUCTION || "true";

    const BACKEND_URL = process.env.BACKEND_URL || "http://localhost";

    const BARTENDER_URL = process.env.BARTENDER_URL || "http://localhost:81";

    //? Adds the role "DEV" to the developer which means no UI elements or routes are guarded/hidden.
    const SET_ROLE_AS_DEV = process.env.SET_ROLE_AS_DEV || "false";

    return {
      production: PRODUCTION === "true", //Convert string to bool
      backendUrl: BACKEND_URL,
      bartenderUrl: BARTENDER_URL,
      setRoleAsDev: SET_ROLE_AS_DEV === "true",
    };
  } catch {
    return defaultValuesDev;
  }
};

export default getConfig;
