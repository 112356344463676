import {
  convertToRoleValueList,
  CRUD,
  READ,
  AllRoles,
  RolePermissions,
  Roles,
} from "src/utils/route-guard/permissions";

// LOT PRODUCTION LIST
const lotProduction = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  LOT_PREP_EDITOR: AllRoles.LOT_PREP_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
};

type LotProductionListRoles = typeof lotProduction;
type LotProductionListUi = Record<string, never>;

export const LotProductionListPermissions: RolePermissions<LotProductionListRoles, LotProductionListUi> = {
  canVisit: convertToRoleValueList(lotProduction),
  ui: {},
  permissions: {
    SUPER_ADMIN: CRUD,
    LOT_EDITOR: READ,
    LOT_PREP_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_EDITOR: CRUD,
    MANUFACTURING_ADMIN: CRUD,
    WAREHOUSE_EDITOR: READ,
  },
};

// LOT PRODUCTION DETAIL
const lotProductionDetail = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  LOT_PREP_EDITOR: AllRoles.LOT_PREP_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
};
type LotProductionDetailRoles = typeof lotProductionDetail;
interface LotProductionDetailUi {
  canSeeStartProductionButton: Roles;
  canSeeStepBackStatusButton: Roles;
}

export const LotProductionDetailPermissions: RolePermissions<LotProductionDetailRoles, LotProductionDetailUi> = {
  canVisit: convertToRoleValueList(lotProductionDetail),
  ui: {
    canSeeStartProductionButton: [AllRoles.SUPER_ADMIN, AllRoles.MANUFACTURING_EDITOR, AllRoles.MANUFACTURING_ADMIN],
    canSeeStepBackStatusButton: [AllRoles.SUPER_ADMIN, AllRoles.MANUFACTURING_ADMIN],
  },
  permissions: {
    SUPER_ADMIN: CRUD,
    LOT_EDITOR: READ,
    LOT_PREP_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_EDITOR: CRUD,
    MANUFACTURING_ADMIN: CRUD,
    WAREHOUSE_EDITOR: READ,
  },
};

// LOT PRODUCTION BOX STATION
const lotProductionBoxStation = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
};

type LotProductionBoxStationRoles = typeof lotProductionBoxStation;
type LotProductionBoxStationUi = Record<string, never>;

export const LotProductionBoxStationPermissions: RolePermissions<
  LotProductionBoxStationRoles,
  LotProductionBoxStationUi
> = {
  canVisit: convertToRoleValueList(lotProductionBoxStation),
  ui: {},
  permissions: {
    SUPER_ADMIN: CRUD,
    LOT_EDITOR: CRUD,
    MANUFACTURING_EDITOR: CRUD,
    MANUFACTURING_ADMIN: CRUD,
  },
};

// LOT PRODUCTION LABEL STATION
const lotProductionLabelStation = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
};

type LotProductionLabelStationRoles = typeof lotProductionLabelStation;
type LotProductionLabelStationUi = Record<string, never>;

export const LotProductionLabelStationPermissions: RolePermissions<
  LotProductionLabelStationRoles,
  LotProductionLabelStationUi
> = {
  canVisit: convertToRoleValueList(lotProductionLabelStation),
  ui: {},
  permissions: {
    SUPER_ADMIN: CRUD,
    LOT_EDITOR: CRUD,
    MANUFACTURING_EDITOR: CRUD,
    MANUFACTURING_ADMIN: CRUD,
  },
};
