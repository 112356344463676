import { useEffect, useState } from "react";
import { useMsal, useAccount, useIsAuthenticated, IMsalContext } from "@azure/msal-react";
import { protectedResources } from "src/api/activedirectory/authConfig";
import { updateHeaderToken } from "src/utils/graphqlClient";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";

export type IIdTokenClaims = Record<string, unknown> & {
  groups: string[];
};

export async function acquireToken(msal: IMsalContext): Promise<boolean> {
  try {
    const response = await msal.instance.acquireTokenSilent({
      scopes: protectedResources.graphql.scopes,
      account: msal.accounts[0],
    });
    updateHeaderToken(response);
    return true;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      msal.instance.acquireTokenRedirect({
        scopes: protectedResources.graphql.scopes,
      });
    }
    return false;
  }
}

export function useGetAccount(): AccountInfo | null {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  //The one that fires on start
  useEffect(() => {
    if (inProgress === "none" && !isAuthenticated) {
      instance.loginRedirect().catch((e: any) => {
        console.log(e);
      });
    }
  }, [inProgress, isAuthenticated, instance]);

  return account;
}

// export function useGetAccount(): { account: AccountInfo | null; tokenAcquired: boolean } {
//   const { instance, accounts, inProgress } = useMsal();
//   const account = useAccount(accounts[0] || {});
//   const isAuthenticated = useIsAuthenticated();
//   const [tokenAcquired, setTokenAcquired] = useState<boolean>(false);

//   const acquireToken = (): void => {
//     if (account && inProgress === "none") {
//       instance
//         .acquireTokenSilent({
//           scopes: protectedResources.graphql.scopes,
//           account: account,
//         })
//         .then((response) => {
//           updateToken(response.accessToken);
//           updateHeaderToken(response);
//           setTokenAcquired(true);
//         })
//         .catch((error) => {
//           setTokenAcquired(false);
//           // in case if silent token acquisition fails, fallback to an interactive method
//           if (error instanceof InteractionRequiredAuthError) {
//             if (account && inProgress === "none") {
//               instance
//                 .acquireTokenRedirect({
//                   scopes: protectedResources.graphql.scopes,
//                 })
//                 .catch((error) => console.log(error));
//             }
//           }
//         });
//     }
//   };

//   //? Maybe memo is wrong here...
//   useMemo(() => {
//     // This will be run on component mount
//     const callbackId = instance.addEventCallback((message: any) => {
//       // This will be run every time an event is emitted after registering this callback
//       if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
//         const result = message.payload;
//         //console.log("result message payload", result);
//         const expiresOn = new Date(result.expiresOn);
//         const millisTillTimeout = Math.abs(expiresOn.getTime() - Date.now());
//         // console.log("time miliseconds", millisTillTimeout);
//         setTimeout(acquireToken, millisTillTimeout);
//       }
//     });

//     return () => {
//       // This will be run on component unmount
//       if (callbackId) {
//         setTokenAcquired(false);
//         instance.removeEventCallback(callbackId);
//       }
//     };
//   }, [account, instance]);

//   useEffect((): void => {
//     if (account && inProgress === "none") {
//       console.log("startup");
//       //acquireToken();
//     }
//   }, [account, inProgress]);

//   //The one that fires on start
//   useEffect(() => {
//     if (inProgress === "none" && !isAuthenticated) {
//       instance.loginRedirect().catch((e: any) => {
//         console.log(e);
//       });
//     }
//   }, [inProgress, isAuthenticated, instance]);

//   return { account, tokenAcquired };
// }

export function useRefreshToken(): boolean {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [tokenAcquired, setTokenAcquired] = useState<boolean>(false);

  useEffect(() => {
    if (account && inProgress === "none") {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.graphql.scopes,
          account: account,
        })
        .then((response) => {
          updateHeaderToken(response);
          setTokenAcquired(true);
        })
        .catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === "none") {
              instance
                .acquireTokenRedirect({
                  scopes: protectedResources.graphql.scopes,
                })
                .catch((error) => console.log(error));
            }
          }
        });
    }
  }, [account, inProgress, instance]);

  return tokenAcquired;
}
