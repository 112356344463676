import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React, { FC } from "react";

/**
 * We usually use the "light" version of the fontawesome pro icons.
 *
 * ADD NEW ICONS:
 * Create a react component for it below,
 * add it to the enum in the icons index file, then export it there.
 *
 * LICENSE:
 * Font Awesome Pro 6.0.0-beta3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2021 Fonticons, Inc.
 */

const svgProps = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 640 512",
};

export const AnglesLeft: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M202.7 84.1C196.1 78.2 186 78.73 180.1 85.29L36.1 245.3c-5.469 6.125-5.469 15.31 0 21.44l144 159.1c5.906 6.562 16.03 7.094 22.59 1.188c6.549-5.938 7.064-16.03 1.188-22.62L69.53 256l134.4-149.3C209.5 100.5 209.6 90.38 202.7 84.1zM261.5 256l134.4-149.3c5.596-6.23 5.73-16.35-1.188-22.62c-6.562-5.906-16.69-5.375-22.59 1.188l-144 159.1c-5.469 6.125-5.469 15.31 0 21.44l144 159.1c5.906 6.562 16.03 7.094 22.59 1.188c6.549-5.938 7.064-16.03 1.188-22.62L261.5 256z" />
  </SvgIcon>
);
export const ArrowRotateLeft: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M480 256c0 123.5-100.4 223.9-223.9 223.9c-69.41 0-133.9-31.3-176.7-86.05c-5.438-6.938-4.203-17 2.75-22.44c6.984-5.531 17.03-4.25 22.47 2.75C141.3 421.1 196.5 448 256 448c105.9 0 192-86.13 192-192s-86.13-192-192-192C187.1 64 124.5 100.7 90.15 160H176C184.8 160 192 167.2 192 176S184.8 192 176 192h-128C39.16 192 32 184.8 32 176v-128C32 39.16 39.16 32 48 32S64 39.16 64 48v93.56C104.4 73.87 176.6 32.11 256.1 32.11C379.6 32.11 480 132.5 480 256z" />
  </SvgIcon>
);
export const BarcodeRead: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M48 32C39.16 32 32 39.16 32 48V144C32 152.8 24.84 160 16 160C7.164 160 0 152.8 0 144V48C0 21.49 21.49 0 48 0H144C152.8 0 160 7.164 160 16C160 24.84 152.8 32 144 32H48zM160 144V368C160 376.8 152.8 384 144 384C135.2 384 128 376.8 128 368V144C128 135.2 135.2 128 144 128C152.8 128 160 135.2 160 144zM192 144C192 135.2 199.2 128 208 128C216.8 128 224 135.2 224 144V368C224 376.8 216.8 384 208 384C199.2 384 192 376.8 192 368V144zM416 144C416 135.2 423.2 128 432 128C440.8 128 448 135.2 448 144V368C448 376.8 440.8 384 432 384C423.2 384 416 376.8 416 368V144zM288 144V368C288 376.8 280.8 384 272 384C263.2 384 256 376.8 256 368V144C256 135.2 263.2 128 272 128C280.8 128 288 135.2 288 144zM336 144C336 135.2 343.2 128 352 128C360.8 128 368 135.2 368 144V368C368 376.8 360.8 384 352 384C343.2 384 336 376.8 336 368V144zM544 48C544 39.16 536.8 32 528 32H432C423.2 32 416 24.84 416 16C416 7.164 423.2 0 432 0H528C554.5 0 576 21.49 576 48V144C576 152.8 568.8 160 560 160C551.2 160 544 152.8 544 144V48zM144 480C152.8 480 160 487.2 160 496C160 504.8 152.8 512 144 512H48C21.49 512 0 490.5 0 464V368C0 359.2 7.164 352 16 352C24.84 352 32 359.2 32 368V464C32 472.8 39.16 480 48 480H144zM544 368C544 359.2 551.2 352 560 352C568.8 352 576 359.2 576 368V464C576 490.5 554.5 512 528 512H432C423.2 512 416 504.8 416 496C416 487.2 423.2 480 432 480H528C536.8 480 544 472.8 544 464V368z" />
  </SvgIcon>
);
export const BoxLight: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M342.4 32C367.7 32 390.6 46.9 400.9 70.01L442.5 163.6C446.1 171.8 448 180.6 448 189.6V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V189.6C0 180.6 1.879 171.8 5.516 163.6L47.11 70.01C57.38 46.89 80.3 32 105.6 32H342.4zM342.4 64H240V160H405.9L371.7 83C366.5 71.45 355.1 64 342.4 64V64zM416 192H32V416C32 433.7 46.33 448 64 448H384C401.7 448 416 433.7 416 416V192zM42.13 160H208V64H105.6C92.95 64 81.49 71.45 76.35 83L42.13 160z" />
  </SvgIcon>
);
export const BoxRegular: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M342.4 32C367.7 32 390.6 46.9 400.9 70.01L442.5 163.6C446.1 171.8 448 180.6 448 189.6V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V189.6C0 180.6 1.879 171.8 5.516 163.6L47.11 70.01C57.38 46.89 80.3 32 105.6 32H342.4zM342.4 80H248V160H388.4L357 89.5C354.5 83.72 348.7 80 342.4 80V80zM400 208H48V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V208zM59.64 160H200V80H105.6C99.27 80 93.54 83.72 90.97 89.5L59.64 160z" />
  </SvgIcon>
);
export const BoxCircleCheck: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M32 192V416C32 433.7 46.33 448 64 448H275.2C281 459.4 288.1 470.2 296.2 480H64C28.65 480 0 451.3 0 416V189.6C0 180.6 1.879 171.8 5.516 163.6L47.11 70.01C57.38 46.89 80.3 32 105.6 32H342.4C367.7 32 390.6 46.9 400.9 70.01L442.5 163.6C446.1 171.8 448 180.6 448 189.6V192H32zM240 160H405.9L371.7 83C366.5 71.45 355.1 64 342.4 64H240V160zM208 64H105.6C92.95 64 81.49 71.45 76.35 83L42.13 160H208L208 64zM499.3 324.7C505.6 330.9 505.6 341.1 499.3 347.3L427.3 419.3C421.1 425.6 410.9 425.6 404.7 419.3L364.7 379.3C358.4 373.1 358.4 362.9 364.7 356.7C370.9 350.4 381.1 350.4 387.3 356.7L416 385.4L476.7 324.7C482.9 318.4 493.1 318.4 499.3 324.7H499.3zM288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368zM432 480C493.9 480 544 429.9 544 368C544 306.1 493.9 256 432 256C370.1 256 320 306.1 320 368C320 429.9 370.1 480 432 480z" />
  </SvgIcon>
);
export const BoxOpen: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M320 77.23L559.6 49.48C573.7 47.85 587.2 55.65 592.7 68.66L624.7 143.3C634.6 166.2 621.3 192.4 596.1 198L437.8 234.8C418.8 239.1 399.2 231.7 387.9 215.9L320 120.9L252.1 215.9C240.8 231.7 221.2 239.1 202.3 234.8L43.04 198C18.75 192.4 5.449 166.2 15.27 143.3L47.26 68.66C52.84 55.65 66.3 47.85 80.36 49.48L320 77.23zM44.68 155.9C42.72 160.5 45.38 165.7 50.24 166.9L209.4 203.6C215.7 205.1 222.3 202.6 226.1 197.3L291.2 106.1L76.68 81.27L44.68 155.9zM413.9 197.3C417.7 202.6 424.3 205.1 430.6 203.6L589.8 166.9C594.6 165.7 597.3 160.5 595.3 155.9L563.3 81.27L348.8 106.1L413.9 197.3zM544 236.7L576 227.6V378.5C576 400.5 561 419.7 539.6 425.1L331.6 477.1C324 479 316 479 308.4 477.1L100.4 425.1C78.99 419.7 64 400.5 64 378.5V227.6L96 236.7V378.5C96 385.9 100.1 392.3 108.1 394L304 443V207.1C304 199.2 311.2 191.1 320 191.1C328.8 191.1 336 199.2 336 207.1V443L531.9 394C539 392.3 544 385.9 544 378.5L544 236.7z" />
  </SvgIcon>
);
export const CalendarXmark: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M246.6 336L299.3 388.7C305.6 394.9 305.6 405.1 299.3 411.3C293.1 417.6 282.9 417.6 276.7 411.3L224 358.6L171.3 411.3C165.1 417.6 154.9 417.6 148.7 411.3C142.4 405.1 142.4 394.9 148.7 388.7L201.4 336L148.7 283.3C142.4 277.1 142.4 266.9 148.7 260.7C154.9 254.4 165.1 254.4 171.3 260.7L224 313.4L276.7 260.7C282.9 254.4 293.1 254.4 299.3 260.7C305.6 266.9 305.6 277.1 299.3 283.3L246.6 336zM128 64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0C120.8 0 128 7.164 128 16V64zM32 448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192H32V448zM32 128V160H416V128C416 110.3 401.7 96 384 96H64C46.33 96 32 110.3 32 128z" />
  </SvgIcon>
);
export const Check: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M475.3 123.3l-272 272C200.2 398.4 196.1 400 192 400s-8.188-1.562-11.31-4.688l-144-144c-6.25-6.25-6.25-16.38 0-22.62s16.38-6.25 22.62 0L192 361.4l260.7-260.7c6.25-6.25 16.38-6.25 22.62 0S481.6 117.1 475.3 123.3z" />
  </SvgIcon>
);
export const CircleCheck: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M340.7 180.7L224 297.4L171.3 244.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62l64 64C215.8 334.4 219.9 336 224 336s8.188-1.562 11.31-4.688l128-128c6.25-6.25 6.25-16.38 0-22.62S346.9 174.4 340.7 180.7zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480z" />
  </SvgIcon>
);
export const ClipboardCheck: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M320 64c-8.844 0-16 7.156-16 16S311.2 96 320 96c17.64 0 32 14.34 32 32v320c0 17.66-14.36 32-32 32H64c-17.64 0-32-14.34-32-32V128c0-17.66 14.36-32 32-32c8.844 0 16-7.156 16-16S72.84 64 64 64C28.7 64 0 92.72 0 128v320c0 35.28 28.7 64 64 64h256c35.3 0 64-28.72 64-64V128C384 92.72 355.3 64 320 64zM112 128h160C280.8 128 288 120.8 288 112S280.8 96 272 96h-24.88C252.6 86.55 256 75.72 256 64c0-35.35-28.65-64-64-64S128 28.65 128 64c0 11.72 3.379 22.55 8.877 32H112C103.2 96 96 103.2 96 112S103.2 128 112 128zM192 32c17.64 0 32 14.36 32 32s-14.36 32-32 32S160 81.64 160 64S174.4 32 192 32zM84.69 299.3l64 64C151.8 366.4 155.9 368 160 368s8.188-1.562 11.31-4.688l128-128c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L160 329.4L107.3 276.7c-6.25-6.25-16.38-6.25-22.62 0S78.44 293.1 84.69 299.3z" />
  </SvgIcon>
);
export const ClipboardList: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M112 128h160C280.8 128 288 120.8 288 112S280.8 96 272 96h-24.88C252.6 86.55 256 75.72 256 64c0-35.35-28.65-64-64-64S128 28.65 128 64c0 11.72 3.379 22.55 8.877 32H112C103.2 96 96 103.2 96 112S103.2 128 112 128zM192 32c17.64 0 32 14.36 32 32s-14.36 32-32 32S160 81.64 160 64S174.4 32 192 32zM320 64c-8.844 0-16 7.156-16 16S311.2 96 320 96c17.64 0 32 14.34 32 32v320c0 17.66-14.36 32-32 32H64c-17.64 0-32-14.34-32-32V128c0-17.66 14.36-32 32-32c8.844 0 16-7.156 16-16S72.84 64 64 64C28.7 64 0 92.72 0 128v320c0 35.28 28.7 64 64 64h256c35.3 0 64-28.72 64-64V128C384 92.72 355.3 64 320 64zM72 256c0 13.25 10.75 24 24 24c13.26 0 24-10.75 24-24c0-13.26-10.74-24-24-24C82.75 232 72 242.7 72 256zM96 328c-13.25 0-24 10.74-24 24c0 13.25 10.75 24 24 24c13.26 0 24-10.75 24-24C120 338.7 109.3 328 96 328zM304 240h-128C167.2 240 160 247.2 160 256s7.156 16 16 16h128C312.8 272 320 264.8 320 256S312.8 240 304 240zM304 336h-128C167.2 336 160 343.2 160 352s7.156 16 16 16h128c8.844 0 16-7.156 16-16S312.8 336 304 336z" />
  </SvgIcon>
);
export const Copy: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M272 416C263.2 416 256 423.2 256 432V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V192c0-17.67 14.33-32 32-32h112C184.8 160 192 152.8 192 144C192 135.2 184.8 128 176 128H63.99c-35.35 0-64 28.65-64 64l.0098 256C0 483.3 28.65 512 64 512h160c35.35 0 64-28.65 64-64v-16C288 423.2 280.8 416 272 416zM502.6 86.63l-77.25-77.25C419.4 3.371 411.2 0 402.7 0H288C252.7 0 224 28.65 224 64v256c0 35.35 28.65 64 64 64h160c35.35 0 64-28.65 64-64V109.3C512 100.8 508.6 92.63 502.6 86.63zM416 45.25L466.7 96H416V45.25zM480 320c0 17.67-14.33 32-32 32h-160c-17.67 0-32-14.33-32-32V64c0-17.67 14.33-32 32-32h96l.0026 64c0 17.67 14.33 32 32 32H480V320z" />
  </SvgIcon>
);

export const Download: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M245.4 379.1C248.4 382.7 252.2 384 256 384s7.594-1.344 10.62-4.047l144-128c6.594-5.859 7.219-15.98 1.344-22.58c-5.875-6.625-16.06-7.234-22.59-1.328L272 332.4V16C272 7.156 264.8 0 256 0S240 7.156 240 16v316.4L122.6 228C116.1 222.1 105.9 222.8 100 229.4C94.16 235.1 94.78 246.1 101.4 251.1L245.4 379.1zM448 320h-48c-8.836 0-16 7.162-16 16c0 8.836 7.164 16 16 16H448c17.67 0 32 14.33 32 32v64c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32v-64c0-17.67 14.33-32 32-32h48C120.8 352 128 344.8 128 336C128 327.2 120.8 320 112 320H64c-35.35 0-64 28.65-64 64v64c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64v-64C512 348.7 483.3 320 448 320zM440 416c0-13.25-10.75-24-24-24s-24 10.75-24 24s10.75 24 24 24S440 429.3 440 416z" />
  </SvgIcon>
);

export const DeliveredToCustomer: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M498.1 5.629C492.7 1.891 486.4 0 480 0c-5.461 0-10.94 1.399-15.88 4.223l-448 255.1C5.531 266.3-.6875 277.8 .0625 289.1s8.375 22.86 19.62 27.55l103.2 43.01l61.85 146.5C186.2 510.6 189.2 512 191.1 512c2.059 0 4.071-.8145 5.555-2.24l85.75-82.4l120.4 50.16c4.293 1.793 8.5 2.472 12.29 2.472c6.615 0 12.11-2.093 15.68-4.097c8.594-4.828 14.47-13.31 15.97-23.05l64-415.1C513.5 24.72 508.3 12.58 498.1 5.629zM32 288l380.1-217.2l-288.2 255.5L32 288zM200.7 462.3L151.1 344.9l229.5-203.4l-169.5 233.1c-2.906 4-3.797 9.094-2.438 13.84c1.374 4.75 4.844 8.594 9.438 10.41l34.4 13.76L200.7 462.3zM416.7 443.3l-167.7-66.56l225.7-310.3L416.7 443.3z" />
  </SvgIcon>
);
export const FileInvoice: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M80 160h64C152.8 160 160 152.8 160 144S152.8 128 144 128h-64C71.16 128 64 135.2 64 144S71.16 160 80 160zM80 96h64C152.8 96 160 88.84 160 80S152.8 64 144 64h-64C71.16 64 64 71.16 64 80S71.16 96 80 96zM365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM64 272v64c0 17.64 14.36 32 32 32h192c17.64 0 32-14.36 32-32v-64c0-17.64-14.36-32-32-32H96C78.36 240 64 254.4 64 272zM288 336H96v-64h192V336zM304 416h-64c-8.844 0-16 7.156-16 16s7.156 16 16 16h64c8.844 0 16-7.156 16-16S312.8 416 304 416z" />
  </SvgIcon>
);
export const FloppyDisk: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M224 256c-35.2 0-64 28.8-64 64c0 35.2 28.8 64 64 64c35.2 0 64-28.8 64-64C288 284.8 259.2 256 224 256zM433.1 129.1l-83.9-83.9C341.1 37.06 328.8 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 151.2 442.9 138.9 433.1 129.1zM128 80h144V160H128V80zM400 416c0 8.836-7.164 16-16 16H64c-8.836 0-16-7.164-16-16V96c0-8.838 7.164-16 16-16h16v104c0 13.25 10.75 24 24 24h192C309.3 208 320 197.3 320 184V83.88l78.25 78.25C399.4 163.2 400 164.8 400 166.3V416z" />
  </SvgIcon>
);
export const HouseChimneyLight: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M277.4 4.002C283.5-1.334 292.5-1.334 298.6 4.002L384 79.37V56C384 42.75 394.7 31.1 408 31.1H488C501.3 31.1 512 42.75 512 56V192.3L570.6 244C577.2 249.8 577.8 259.1 571.1 266.6C566.2 273.2 556 273.8 549.4 267.1L512 234.1V432C512 476.2 476.2 512 432 512H144C99.82 512 64 476.2 64 432V234.1L26.59 267.1C19.96 273.8 9.849 273.2 4.003 266.6C-1.844 259.1-1.212 249.8 5.414 244L277.4 4.002zM480 164.1V64H416V107.6L480 164.1zM96 206.7V432C96 458.5 117.5 480 144 480H208V320C208 302.3 222.3 288 240 288H336C353.7 288 368 302.3 368 320V480H432C458.5 480 480 458.5 480 432V206.7L288 37.34L96 206.7zM240 320V480H336V320H240z" />
  </SvgIcon>
);
export const HouseChimneyRegular: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M272.5 5.7C281.4-1.9 294.6-1.9 303.5 5.7L464 141.9V56C464 42.75 474.7 31.1 488 31.1C501.3 31.1 512 42.75 512 56V182.6L567.5 229.7C577.6 238.3 578.9 253.4 570.3 263.5C561.7 273.6 546.6 274.9 536.5 266.3L512 245.5V432C512 476.2 476.2 512 432 512H144C99.82 512 64 476.2 64 432V245.5L39.53 266.3C29.42 274.9 14.28 273.6 5.7 263.5C-2.876 253.4-1.634 238.3 8.473 229.7L272.5 5.7zM112 204.8V432C112 449.7 126.3 464 144 464H192V312C192 289.9 209.9 272 232 272H344C366.1 272 384 289.9 384 312V464H432C449.7 464 464 449.7 464 432V204.8L288 55.47L112 204.8zM240 320V464H336V320H240z" />
  </SvgIcon>
);
export const InboxIn: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M244.7 315.3C247.8 318.4 251.9 320 256 320s8.188-1.562 11.31-4.688l112-112c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L272 265.4V16c0-8.844-7.156-16-16-16S240 7.156 240 16v249.4L155.3 180.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L244.7 315.3zM464 320h-80c-6.061 0-11.6 3.424-14.31 8.844L342.1 384H169.9l-27.58-55.15C139.6 323.4 134.1 320 128 320H48C21.49 320 0 341.5 0 368v96C0 490.5 21.49 512 48 512h416c26.51 0 48-21.49 48-48v-96C512 341.5 490.5 320 464 320zM480 464c0 8.836-7.162 16-16 16h-416C39.16 480 32 472.8 32 464v-96C32 359.2 39.16 352 48 352h70.11l27.58 55.16C148.4 412.6 153.9 416 159.1 416H352c6.062 0 11.6-3.424 14.31-8.846L393.9 352H464c8.838 0 16 7.164 16 16V464z" />
  </SvgIcon>
);
export const ListUl: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M64 64C81.67 64 96 78.33 96 96C96 113.7 81.67 128 64 128C46.33 128 32 113.7 32 96C32 78.33 46.33 64 64 64zM496 80C504.8 80 512 87.16 512 96C512 104.8 504.8 112 496 112H176C167.2 112 160 104.8 160 96C160 87.16 167.2 80 176 80H496zM496 240C504.8 240 512 247.2 512 256C512 264.8 504.8 272 496 272H176C167.2 272 160 264.8 160 256C160 247.2 167.2 240 176 240H496zM496 400C504.8 400 512 407.2 512 416C512 424.8 504.8 432 496 432H176C167.2 432 160 424.8 160 416C160 407.2 167.2 400 176 400H496zM64 288C46.33 288 32 273.7 32 256C32 238.3 46.33 224 64 224C81.67 224 96 238.3 96 256C96 273.7 81.67 288 64 288zM64 384C81.67 384 96 398.3 96 416C96 433.7 81.67 448 64 448C46.33 448 32 433.7 32 416C32 398.3 46.33 384 64 384z" />
  </SvgIcon>
);
export const MagnifyingGlass: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M507.3 484.7l-141.5-141.5C397 306.8 415.1 259.7 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c51.68 0 98.85-18.96 135.2-50.15l141.5 141.5C487.8 510.4 491.9 512 496 512s8.188-1.562 11.31-4.688C513.6 501.1 513.6 490.9 507.3 484.7zM208 384C110.1 384 32 305 32 208S110.1 32 208 32S384 110.1 384 208S305 384 208 384z" />
  </SvgIcon>
);
export const PenToSquare: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z" />
  </SvgIcon>
);
export const Plus: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M432 256C432 264.8 424.8 272 416 272h-176V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z" />
  </SvgIcon>
);
export const Print: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M416 320H96c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-128C448 334.3 433.7 320 416 320zM416 480H96v-128h320V480zM448 192V70.63C448 62.14 444.6 54 438.6 48l-38.63-38.63C393.1 3.37 385.9 0 377.4 0H128C92.65 0 64 28.65 64 64v128C28.7 192 0 220.7 0 256v112C0 376.8 7.156 384 16 384S32 376.8 32 368V256c0-17.67 14.33-32 32-32h384c17.67 0 32 14.33 32 32v112c0 8.844 7.156 16 16 16s16-7.156 16-16V256C512 220.7 483.3 192 448 192zM416 192H96V64c0-17.67 14.33-32 32-32h249.4L416 70.63V192zM432 248c-13.25 0-24 10.74-24 24c0 13.25 10.75 24 24 24s24-10.75 24-24C456 258.7 445.3 248 432 248z" />
  </SvgIcon>
);
export const QrcodeLight: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M104 104C112.8 104 120 111.2 120 120V136C120 144.8 112.8 152 104 152H88C79.16 152 72 144.8 72 136V120C72 111.2 79.16 104 88 104H104zM144 32C170.5 32 192 53.49 192 80V176C192 202.5 170.5 224 144 224H48C21.49 224 0 202.5 0 176V80C0 53.49 21.49 32 48 32H144zM144 64H48C39.16 64 32 71.16 32 80V176C32 184.8 39.16 192 48 192H144C152.8 192 160 184.8 160 176V80C160 71.16 152.8 64 144 64zM72 376C72 367.2 79.16 360 88 360H104C112.8 360 120 367.2 120 376V392C120 400.8 112.8 408 104 408H88C79.16 408 72 400.8 72 392V376zM144 288C170.5 288 192 309.5 192 336V432C192 458.5 170.5 480 144 480H48C21.49 480 0 458.5 0 432V336C0 309.5 21.49 288 48 288H144zM144 320H48C39.16 320 32 327.2 32 336V432C32 440.8 39.16 448 48 448H144C152.8 448 160 440.8 160 432V336C160 327.2 152.8 320 144 320zM360 104C368.8 104 376 111.2 376 120V136C376 144.8 368.8 152 360 152H344C335.2 152 328 144.8 328 136V120C328 111.2 335.2 104 344 104H360zM256 80C256 53.49 277.5 32 304 32H400C426.5 32 448 53.49 448 80V176C448 202.5 426.5 224 400 224H304C277.5 224 256 202.5 256 176V80zM288 80V176C288 184.8 295.2 192 304 192H400C408.8 192 416 184.8 416 176V80C416 71.16 408.8 64 400 64H304C295.2 64 288 71.16 288 80zM256 304C256 295.2 263.2 288 272 288H336C344.8 288 352 295.2 352 304V372H416V304C416 295.2 423.2 288 432 288C440.8 288 448 295.2 448 304V388C448 396.8 440.8 404 432 404H336C327.2 404 320 396.8 320 388V320H288V472C288 480.8 280.8 488 272 488C263.2 488 256 480.8 256 472V304zM320 448C320 439.2 327.2 432 336 432H352C360.8 432 368 439.2 368 448V464C368 472.8 360.8 480 352 480H336C327.2 480 320 472.8 320 464V448zM432 432C440.8 432 448 439.2 448 448V464C448 472.8 440.8 480 432 480H416C407.2 480 400 472.8 400 464V448C400 439.2 407.2 432 416 432H432z" />
  </SvgIcon>
);
export const QrcodeRegular: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M104 103.1C112.8 103.1 120 111.2 120 119.1V135.1C120 144.8 112.8 151.1 104 151.1H88C79.16 151.1 72 144.8 72 135.1V119.1C72 111.2 79.16 103.1 88 103.1H104zM0 79.1C0 53.49 21.49 31.1 48 31.1H144C170.5 31.1 192 53.49 192 79.1V175.1C192 202.5 170.5 223.1 144 223.1H48C21.49 223.1 0 202.5 0 175.1V79.1zM48 175.1H144V79.1H48V175.1zM72 375.1C72 367.2 79.16 359.1 88 359.1H104C112.8 359.1 120 367.2 120 375.1V391.1C120 400.8 112.8 407.1 104 407.1H88C79.16 407.1 72 400.8 72 391.1V375.1zM0 335.1C0 309.5 21.49 287.1 48 287.1H144C170.5 287.1 192 309.5 192 335.1V431.1C192 458.5 170.5 479.1 144 479.1H48C21.49 479.1 0 458.5 0 431.1V335.1zM48 431.1H144V335.1H48V431.1zM360 103.1C368.8 103.1 376 111.2 376 119.1V135.1C376 144.8 368.8 151.1 360 151.1H344C335.2 151.1 328 144.8 328 135.1V119.1C328 111.2 335.2 103.1 344 103.1H360zM400 31.1C426.5 31.1 448 53.49 448 79.1V175.1C448 202.5 426.5 223.1 400 223.1H304C277.5 223.1 256 202.5 256 175.1V79.1C256 53.49 277.5 31.1 304 31.1H400zM400 79.1H304V175.1H400V79.1zM384 479.1H352V447.1H384V479.1zM416 447.1H448V479.1H416V447.1zM448 415.1H352V383.1H320V479.1H256V287.1H352V319.1H416V287.1H448V415.1z" />
  </SvgIcon>
);
export const Question: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M213.1 32H106.7C47.84 32 0 79.84 0 138.7V160c0 8.844 7.156 16 16 16S32 168.9 32 160V138.7C32 97.48 65.5 64 106.7 64h106.5C254.4 64 288 97.58 288 138.9c0 27-14.62 52-38.16 65.25L152.5 258.9C137.4 267.4 128 283.4 128 300.7V336c0 8.844 7.156 16.01 16 16.01S160 344.8 160 336V300.7c0-5.766 3.125-11.11 8.156-13.95l97.38-54.78C299.1 213.1 320 177.4 320 138.9C320 79.94 272.1 32 213.1 32zM144 400c-17.67 0-32 14.32-32 31.99s14.33 32 32 32s32-14.33 32-32S161.7 400 144 400z" />
  </SvgIcon>
);
export const Shield: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M466.5 83.71l-192-80C269.6 1.67 261.3 0 256 0C250.7 0 242.5 1.67 237.6 3.702l-192 80C27.7 91.1 16 108.6 16 127.1c0 257.2 189.2 384 239.1 384c51.1 0 240-128.2 240-384C496 108.6 484.3 91.1 466.5 83.71zM256 446.5l.0234-381.1c.0059-.0234 0 0 0 0l175.9 73.17C427.8 319.7 319 417.1 256 446.5z" />
  </SvgIcon>
);
export const SquarePlus: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM416 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h320c17.64 0 32 14.36 32 32V416zM320 240h-80V160c0-8.844-7.156-16-16-16S208 151.2 208 160v80H128C119.2 240 112 247.2 112 256S119.2 272 128 272h80V352c0 8.844 7.156 16 16 16s16-7.156 16-16V272H320c8.844 0 16-7.156 16-16S328.8 240 320 240z" />
  </SvgIcon>
);
export const TrashCan: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M432 80h-82.38l-34-56.75C306.1 8.827 291.4 0 274.6 0H173.4C156.6 0 141 8.827 132.4 23.25L98.38 80H16C7.125 80 0 87.13 0 96v16C0 120.9 7.125 128 16 128H32v320c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V128h16C440.9 128 448 120.9 448 112V96C448 87.13 440.9 80 432 80zM171.9 50.88C172.9 49.13 174.9 48 177 48h94c2.125 0 4.125 1.125 5.125 2.875L293.6 80H154.4L171.9 50.88zM352 464H96c-8.837 0-16-7.163-16-16V128h288v320C368 456.8 360.8 464 352 464zM224 416c8.844 0 16-7.156 16-16V192c0-8.844-7.156-16-16-16S208 183.2 208 192v208C208 408.8 215.2 416 224 416zM144 416C152.8 416 160 408.8 160 400V192c0-8.844-7.156-16-16-16S128 183.2 128 192v208C128 408.8 135.2 416 144 416zM304 416c8.844 0 16-7.156 16-16V192c0-8.844-7.156-16-16-16S288 183.2 288 192v208C288 408.8 295.2 416 304 416z" />
  </SvgIcon>
);
export const Vial: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M412.7 199.2L355.8 256H154.9l157.4-157.2c6.256-6.246 6.258-16.38 .0078-22.63c-6.246-6.246-16.37-6.25-22.62-.0078l-256.8 256.4c-38.75 38.75-45.13 102-9.375 143.5C44.08 500 72.76 512 101.5 512h.4473c26.38 0 52.75-10 72.88-30.12l260.5-260c6.26-6.246 6.264-16.38 .0117-22.64C429.1 192.1 418.1 192.1 412.7 199.2zM152.2 459.2C138.8 472.6 120.1 480 101.5 480c-20.59 0-40.15-9.014-53.68-24.76c-23.93-27.78-20.53-71.7 7.744-99.97L122.9 288h200.9L152.2 459.2zM507.3 180.7l-176-176C328.2 1.562 324.1 0 320 0s-8.188 1.562-11.31 4.688c-6.25 6.25-6.25 16.38 0 22.62l176 176C487.8 206.4 491.9 208 496 208s8.188-1.562 11.31-4.688C513.6 197.1 513.6 186.9 507.3 180.7z" />
  </SvgIcon>
);
export const VialsLight: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M176 96C167.2 96 160 103.2 160 112V256H64V112C64 103.2 56.84 96 48 96S32 103.2 32 112v288C32 444.1 67.88 480 112 480S192 444.1 192 400v-288C192 103.2 184.8 96 176 96zM160 400C160 426.5 138.5 448 112 448S64 426.5 64 400V288h96V400zM464 96C455.2 96 448 103.2 448 112V256h-96V112C352 103.2 344.8 96 336 96S320 103.2 320 112v288c0 44.13 35.88 80 80 80s80-35.88 80-80v-288C480 103.2 472.8 96 464 96zM448 400c0 26.47-21.53 48-48 48S352 426.5 352 400V288h96V400zM208 32h-192C7.156 32 0 39.16 0 48S7.156 64 16 64h192C216.8 64 224 56.84 224 48S216.8 32 208 32zM496 32h-192C295.2 32 288 39.16 288 48S295.2 64 304 64h192C504.8 64 512 56.84 512 48S504.8 32 496 32z" />
  </SvgIcon>
);
export const VialsRegular: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M488 32h-176C298.8 32 288 42.8 288 56c0 13.2 10.8 24 24 24H320v316.5c0 41.76 30.47 79.12 72.03 83.15C439.7 484.2 480 446.8 480 400v-320h8C501.2 80 512 69.2 512 56C512 42.8 501.2 32 488 32zM432 256h-64V80h64V256zM200 32h-176C10.8 32 0 42.8 0 56C0 69.2 10.8 80 24 80H32v316.5c0 41.76 30.47 79.12 72.03 83.15C151.7 484.2 192 446.8 192 400v-320h8C213.2 80 224 69.2 224 56C224 42.8 213.2 32 200 32zM144 256h-64V80h64V256z" />
  </SvgIcon>
);
export const WarehouseFull: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M326.2 35.98C322.2 34.3 317.8 34.3 313.8 35.98L51.58 146.4C39.72 151.4 32 162.1 32 175.9V496C32 504.8 24.84 512 16 512C7.164 512 0 504.8 0 496V175.9C0 150.1 15.43 126.9 39.16 116.9L301.4 6.484C313.3 1.469 326.7 1.469 338.6 6.484L600.8 116.9C624.6 126.9 640 150.1 640 175.9V496C640 504.8 632.8 512 624 512C615.2 512 608 504.8 608 496V175.9C608 162.1 600.3 151.4 588.4 146.4L326.2 35.98zM96 224C96 206.3 110.3 192 128 192H512C529.7 192 544 206.3 544 224V496C544 504.8 536.8 512 528 512C519.2 512 512 504.8 512 496V224H128V496C128 504.8 120.8 512 112 512C103.2 512 96 504.8 96 496V224zM480 416V464C480 490.5 458.5 512 432 512H208C181.5 512 160 490.5 160 464V303.1C160 277.5 181.5 255.1 208 255.1H304C330.5 255.1 352 277.5 352 303.1V368H432C458.5 368 480 389.5 480 416zM352 400V480H432C440.8 480 448 472.8 448 464V416C448 407.2 440.8 400 432 400H352zM320 480V400H192V464C192 472.8 199.2 480 208 480H320zM304 287.1H208C199.2 287.1 192 295.2 192 303.1V368H320V303.1C320 295.2 312.8 287.1 304 287.1z" />
  </SvgIcon>
);
export const Xmark: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
  </SvgIcon>
);
export const PaperPlane: FC<SvgIconProps> = (props) => (
  <SvgIcon {...svgProps} {...props}>
    <path d="M498.1 5.629C492.7 1.891 486.4 0 480 0c-5.461 0-10.94 1.399-15.88 4.223l-448 255.1C5.531 266.3-.6875 277.8 .0625 289.1s8.375 22.86 19.62 27.55l103.2 43.01l61.85 146.5C186.2 510.6 189.2 512 191.1 512c2.059 0 4.071-.8145 5.555-2.24l85.75-82.4l120.4 50.16c4.293 1.793 8.5 2.472 12.29 2.472c6.615 0 12.11-2.093 15.68-4.097c8.594-4.828 14.47-13.31 15.97-23.05l64-415.1C513.5 24.72 508.3 12.58 498.1 5.629zM32 288l380.1-217.2l-288.2 255.5L32 288zM200.7 462.3L151.1 344.9l229.5-203.4l-169.5 233.1c-2.906 4-3.797 9.094-2.438 13.84c1.374 4.75 4.844 8.594 9.438 10.41l34.4 13.76L200.7 462.3zM416.7 443.3l-167.7-66.56l225.7-310.3L416.7 443.3z" />
  </SvgIcon>
);
