import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductListPermissions } from "src/utils/route-guard/permissions/productPermissions";
import {
  LotManagementListPermissions,
  LotManagementDetailPermissions,
  LotManagementCreatePermissions,
  LotManagementEditPermissions,
} from "src/utils/route-guard/permissions/lotManagementPermissions";
import { LotProductionListPermissions } from "src/utils/route-guard/permissions/lotProductionPermissions";
import { UdiListPermissions } from "src/utils/route-guard/permissions/udiPermissions";
import { WarehouseListPermissions } from "src/utils/route-guard/permissions/warehousePermissions";
import { InvoiceListPermissions } from "src/utils/route-guard/permissions/invoicePermissions";
import { InfectionControlPermissions } from "src/utils/route-guard/permissions/infectionPermissions";
import { AllRoles, Roles } from "src/utils/route-guard/permissions";

/// Components
// Static Loaded
import DCSideBar, { IListItem, IListSubItem } from "dc-ui/components/DCSideBar/DCSideBar";
import { Icons } from "dc-parts/icon/IconIndex";
import { DCIcon } from "dc-parts/icon/DCIcon";
import { routes } from "src/utils/routes";

/// Icons
//Static Loaded
//Dynamic loaded

const iconSx = {
  width: "20px",
  height: "20px",
  display: "block",
  marginLeft: "0",
  marginRight: "auto",
  marginBottom: "auto",
  marginTop: "auto",
};

type SideBarProps = {
  id?: string;
  userRoles: Roles;
};

const SideBar: FC<SideBarProps> = ({ userRoles }) => {
  const routeHook = useLocation();
  const navigate = useNavigate();

  const isPageVisitable = (canVisit: Roles) => canVisit.filter((role) => userRoles.includes(role)).length > 0;
  const isDev = userRoles.includes(AllRoles.DEV);

  const isProductListVisitable = isPageVisitable(ProductListPermissions.canVisit) || isDev;
  const isLotManagementListVisitable = isPageVisitable(LotManagementListPermissions.canVisit) || isDev;
  const isLotManagementDetailVisitable = isPageVisitable(LotManagementDetailPermissions.canVisit) || isDev;
  const isLotManagementCreateVisitable = isPageVisitable(LotManagementCreatePermissions.canVisit) || isDev;
  const isLotManagementEditVisitable = isPageVisitable(LotManagementEditPermissions.canVisit) || isDev;
  const isLotProductionVisitable = isPageVisitable(LotProductionListPermissions.canVisit) || isDev;
  const isUdiVisitable = isPageVisitable(UdiListPermissions.canVisit) || isDev;
  const isWarehouseVisitable = isPageVisitable(WarehouseListPermissions.canVisit) || isDev;
  const isInvoiceVisitable = isPageVisitable(InvoiceListPermissions.canVisit) || isDev;
  const isInfectionControlVisitable = isPageVisitable(InfectionControlPermissions.canVisit) || isDev;

  useEffect(() => {
    console.log(routeHook);
  }, [routeHook]);

  const productSubList: IListSubItem[] = [
    //Product subpages
    {
      ...routes.product.detail,
      hidden: true,
      parentUrl: routes.product.list.url,
    },
  ];

  const supplyChainSubList: IListSubItem[] = [
    {
      ...routes.lot.management.list,
      onClick: () => {
        navigate(routes.lot.management.list.url, { replace: true });
      },
    },
  ];

  // const invoiceSubList: IListSubItem[] = [
  //   {
  //     ...routes.invoice.list,
  //     onClick: () => {
  //       navigate(routes.invoice.list.url, { replace: true });
  //     },
  //   },
  // ];

  if (isLotManagementDetailVisitable) {
    supplyChainSubList.push({ ...routes.lot.management.detail, hidden: true });
  }

  if (isLotManagementEditVisitable) {
    supplyChainSubList.push({ ...routes.lot.management.edit, hidden: true });
  }

  if (isLotManagementCreateVisitable) {
    supplyChainSubList.push({ ...routes.lot.management.create, hidden: true });
  }

  if (isLotProductionVisitable) {
    supplyChainSubList.push(
      {
        ...routes.lot.production.list,
        onClick: () => {
          navigate(routes.lot.production.list.url, { replace: true });
        },
      },
      { ...routes.lot.production.detail, hidden: true },
      { ...routes.lot.production.boxStation, hidden: true },
      { ...routes.lot.production.labelStation, hidden: true },
    );
  }

  if (isWarehouseVisitable) {
    supplyChainSubList.push(
      {
        ...routes.warehouse.home,
        onClick: () => {
          navigate(routes.warehouse.home.url);
        },
      },
      { ...routes.warehouse.list, hidden: true },
      { ...routes.warehouse.stock, hidden: true },
      { ...routes.warehouse.detail, hidden: true },
    );
  }

  // if (isInvoiceVisitable) {
  //   invoiceSubList.push({
  //     ...routes.invoice.create,
  //     onClick: () => {
  //       navigate(routes.invoice.create.url, { replace: true });
  //     },
  //   });
  // }

  const udiSubList: IListSubItem[] = [
    //UDI subpages
    { ...routes.udi.detail, hidden: true },
  ];

  // Headers
  const iconList: IListItem[] = [
    {
      ...routes.home,
      icon: <DCIcon iconName={Icons.HouseChimneyRegular} sx={iconSx} />,
      onClick: () => {
        navigate(routes.home.url, { replace: true });
      },
    },
  ];

  if (isProductListVisitable) {
    iconList.push({
      ...routes.product.list,
      icon: <DCIcon iconName={Icons.VialsRegular} sx={iconSx} />,
      subItems: productSubList,
      onClick: () => {
        navigate(routes.product.list.url, { replace: true });
      },
    });
  }

  if (isLotManagementListVisitable) {
    iconList.push({
      ...routes.lot.management.list,
      text: "Supply Chain",
      icon: <DCIcon iconName={Icons.BoxRegular} sx={iconSx} />,
      subItems: supplyChainSubList,
      onClick: () => {
        navigate(routes.lot.management.list.url, { replace: true });
      },
    });
  }
  // if (isInvoiceVisitable) {
  //   iconList.push({
  //     ...routes.invoice.list,
  //     text: "Invoice",
  //     icon: <DCIcon iconName={Icons.PenToSquare} sx={iconSx} />,
  //     subItems: invoiceSubList,
  //     onClick: () => {
  //       navigate(routes.invoice.list.url, { replace: true });
  //     },
  //   });
  // }

  if (isUdiVisitable) {
    iconList.push({
      ...routes.udi.list,
      icon: <DCIcon iconName={Icons.QrcodeRegular} sx={iconSx} />,
      subItems: udiSubList,
      onClick: () => {
        navigate(routes.udi.list.url, { replace: true });
      },
    });
  }

  if (isInfectionControlVisitable) {
    iconList.push({
      ...routes.infection.list,
      icon: <DCIcon iconName={Icons.Shield} sx={iconSx} />,
      subItems: [],
      onClick: () => {
        navigate(routes.infection.list.url, { replace: true });
      },
    });
  }

  return (
    <DCSideBar
      iconList={iconList}
      expandedDrawerWidth={270}
      collapsedDrawerWidth={64}
      DrawerIcon={<DCIcon iconName={Icons.AnglesLeft} sx={iconSx} />}
      selectedKey={routeHook.pathname}
    />
  );
};

export default SideBar;
