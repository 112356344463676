import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { GraphQLClient } from "graphql-request";
import { useEffect, useState } from "react";
import { protectedResources } from "src/api/activedirectory/authConfig";

let header = "";
let token: AuthenticationResult | undefined = undefined;

export const graphqlClient = new GraphQLClient(protectedResources.graphql.endpoint, {});
graphqlClient.setHeader("Authorization", "Bearer ");

export function updateHeaderToken(newToken: AuthenticationResult): void {
  token = newToken;
  graphqlClient.setHeader("Authorization", token ? `Bearer ${token.accessToken}` : "");
  header = token ? `Bearer ${token.accessToken}` : "";
}

/**
 * Used to check if the user is authenticated and tries to keep the value updated
 * Updating of token needs to be done manually by calling acquireToken in auth.tsx
 * @returns boolean
 */
export function useTokenValid(): boolean {
  const msal = useMsal();
  const [tokenValid, setTokenValid] = useState(false);
  const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout | null>(null);

  if (token && token.expiresOn) {
    if (!tokenValid) {
      if (new Date(token.expiresOn).getTime() > Date.now()) {
        setTokenValid(true);
      }
    }
  }

  // A event listener that subscribes to the internal msal event and sets the events
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callbackId = msal.instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (!tokenValid) {
          //? Due to everything being so fast we need to wait a bit before we set the tokenValid to true
          setTimeout(() => {
            setTokenValid(true);
          }, 50);
        }
      } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        // If we ever fail to acquire a token we need to reset the tokenValid to false
        if (tokenValid) {
          setTokenValid(false);
        }
        // cancel the timeout on failure, this will timeout will be started again when the token is acquired
        if (timeoutHandle) {
          clearTimeout(timeoutHandle);
          setTimeoutHandle(null);
        }
      }
    });

    return () => {
      if (callbackId) {
        msal.instance.removeEventCallback(callbackId);
      }
    };
  }, [tokenValid, msal.instance, timeoutHandle]);

  // Check the token state
  useEffect(() => {
    const validateToken = (): void => {
      // If the token is valid we start a timeout to validate it again once it expires
      if (token && token.expiresOn) {
        if (new Date(token.expiresOn).getTime() > Date.now()) {
          if (!tokenValid) {
            setTokenValid(true);
          }
          if (timeoutHandle === null) {
            const millisTillTimeout = Math.abs(token.expiresOn.getTime() - Date.now());
            setTimeoutHandle(setTimeout(validateToken, millisTillTimeout));
          }
        } else {
          if (tokenValid) {
            setTokenValid(false);
          }
        }
      }
    };

    validateToken();

    return (): void => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
        setTimeoutHandle(null);
      }
    };
  }, [timeoutHandle, tokenValid]);

  return tokenValid;
}

export function getHeader(): string {
  return header;
}
